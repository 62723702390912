<template>
  <div class="campaign-state__container">
    <div class="states-input-wrapper">
      <div class="input-container">
        <input type="text" v-model="fu" class="input-value">
      </div>
    </div>
    <div class="campaign-state__carousel-wrapper">
      <carousel :perPage="1" :navigationEnabled="true" :navigationClickTargetSize="5" :paginationEnabled="false" style="width:225px">
        <slide v-for="(banner, i) in banners" :key="i">
          <cc-logo :src="banner" style="height:160px;width: 225px !important; cursor: default;"  />
          <div class="action-controls-wrapper">
            <span @click="openFullScreenImageModal(banner)" class="material-icons-outlined fullscreen-icon">
              fullscreen
            </span>
            <span class="material-icons-outlined edit-icon" @click="select_file">
              edit
            </span>
            <span class="material-icons-outlined remove-icon" v-if="exists(banner)" @click="remove_banner(banner)">
              delete
            </span>
          </div>
        </slide>
      </carousel>
      <toggle-button class="toggle-btn-width"
                     :value="is_active"
                     :sync="true"
                     @change="add_state"
                     :color="'#ff7110'"
                     :height="25"
                     :width="60"/>
      <input style="display:none;"
             type="file"
             :ref="'banner-file-' + fu"
             @change="upload_banner">
    </div>
    <FullScreenImage v-if="showFullScreenImageModal"
                     :closeDialog="closeFullScreenImageModal"
                     :imageInfo="imageInfo"/>
  </div>
    <!-- <div class="col col-6">
        <div class="row d-flex" :class="{ 'justify-content-end' : left, 'justify-content-start' : !left }">
            <div class="col col-2 actions">
                <cc-input :value="fu" :classes="'text-center'" />
            </div>
            <div class="col col-1 actions">
                <toggle-button
                    :value="is_active"
                    :sync="true"
                    @change="add_state"
                    :color="'#ff7110'"
                    :height="30"/>
            </div>
            <div class="col col-5 actions img">
                <carousel :perPage="1" :navigationEnabled="true" :navigationClickTargetSize="5" :paginationEnabled="false" style="width:225px">
                    <slide v-for="(banner, i) in banners" :key="i">
                        <div class="controls-wrapper">
                            <i class="fa fa-trash rmv-btn" v-if="exists(banner)" @click="remove_banner(banner)"></i>
                        </div>
                        <cc-logo :src="banner" @click="select_file" style="height:50px;width: 225px !important;"  />
                    </slide>
                </carousel>
                <input
                    style="display:none;"
                    type="file"
                    :ref="'banner-file-' + fu"
                    @change="upload_banner">
            </div>
        </div>
    </div> -->
</template>

<script>
import SysConfService from "@/services/v3/management/sysconf.service";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import { loaderMixin } from '@/mixins/sweet-loader.mixin'
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import UserService from "@/services/v1/user.service"
import StandardInput from '@/components/ui/inputs/StandardInput.vue'
import FullScreenImage from "@/components/ui/images/FullScreenImage.vue";

export default {
    mixins: [ loaderMixin ],
    props: {
        fu: {
            type: String,
            required: true
        },
        is_active: {
            type: Boolean,
            required: true
        },
        left: {
            type: Boolean,
            default: false
        },
        confs: {
            type: Array,
            required: true
        }
    },
    components: {
        ValidationObserver,
        ValidationProvider,
        StandardInput,
        FullScreenImage,
    },
    data(){
        return{
            showFullScreenImageModal: false,
            imageInfo: undefined, 
            user_svc: new UserService(),
            /* banners: [ "https://place-hold.it/900x200" ], */
            banners: [ "https://i.ibb.co/HPsrnfF/Rectangle60.png" ],
            svc: new SysConfService(),
            breadcrumbs: [
                {
                    name: "Configurações do Sistema",
                    route: 'settings'
                }
            ]
        }
    },
    methods: {
        closeFullScreenImageModal: function() {
          this.showFullScreenImageModal = false;
        },
        openFullScreenImageModal(payload) {
          this.showFullScreenImageModal = true
          this.imageInfo = payload
        },
        exists(banner) {
            return banner != "https://place-hold.it/900x200"
        },
        select_file() {
            this.$refs['banner-file-' + this.fu].click()
        },
        add_state() {
            this.$emit('toggle', this.fu)
        },
        remove_banner(banner) {
            this.present_loader(`removendo banner`)
            let conf = this.confs.find(c => c.key == ('CAMPAIGN_STATE_BANNER_' + this.fu))
            let data = JSON.parse(conf.value)
            data = data.filter(b => b != banner)
            this.svc.update_banners(this.fu, data.length > 0 ? data : null).then(() => {
                this.load()
                this.$emit('reload')
                this.dismiss_loader()
            }).catch(err => {
                ErrorHandlerService.handle(err, this.$store);
            })
        },
        upload_banner(evt) {
            let file = evt.target.files[0];
            this.present_loader('enviando imagem')
            this.svc.upload_banner(this.fu, file).then((response) => response.data).then(data => {
                this.banner_url = data.url
                this.dismiss_loader()
                this.$emit('reload')
            }).catch(err => {
                ErrorHandlerService.handle(err, this.$store);
            })
        },
        load() {
            let conf = this.confs.find(c => c.key == ('CAMPAIGN_STATE_BANNER_' + this.fu))
            if(conf) {
                this.banners = JSON.parse(conf.value)
            }
        }
    },
    created() {
        this.load()
    }
}
</script>

<style lang="scss" scoped>
    @import '@/sass/commons/_variables';

    .error {
        color: $lower-red;
    }

    .btnFeedbackModal {
        border-radius: 5px;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
        border: solid 1px $blue1;
        background-color: $secondary-color;
        font-family: "Source Sans Pro", sans-serif;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
        padding: 15px
    }

    .modal-header h3,.modal-header h4, .modal-header h5 {
        margin-top: 0;
        font-weight: bolder;
        font-size: 1.15rem;
        color: $secondary-color;
        text-transform: uppercase;
    }

    .modal-header {
        border: none;
        .subtitle {
            box-shadow: inset 7px 0px 0px 0px rgba(66,103,178,1);
            padding-left: 1rem;
            color: #314b5f;
            font-size: 0.9rem;
            font-family: Helvetica,sans-serif !important;
            font-weight: lighter;
        }
    }
    .actions {
        display: flex;
        align-items: center;
        padding: 10px;
        button {
            margin-right: 10px;
        }
        &.img {
            padding: 10px 30px;
            .controls-wrapper {
                width: 0;
                height: 0;
                .rmv-btn {
                    color: aliceblue;
                    position: relative;
                    left: 200px;
                    top: 6px;
                    background: left;
                    width: 25px;
                    height: 25px;
                    padding: 5px;
                    text-align: center;
                    &:hover {
                        cursor: pointer;
                        color: $brand-danger;
                    }
                }
            }
        }
    }
    
.campaign-state__container {
  /* display: flex;
  flex-direction: column; */
  margin: 1em auto;
  justify-content: center;
  padding: 5px 25px;
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 6px 0.5px rgba(0,0,0,0.12);
  box-shadow: 0px 0px 6px 0.5px rgba(0,0,0,0.12);
}
.states-input-wrapper {
  margin: 0.5em 0em;
}
.states-input {
  width: 225px;
}
.campaign-state__carousel-wrapper {}
.action-controls-wrapper {
  display: flex;
  position: relative;
  top: -34px;
  flex-direction: row-reverse;
  background-color: rgba(255, 255, 255, 0.74);
  padding: 5px 0px;
}
.edit-icon {
  color: #101010;
  cursor: pointer;
  margin-left: 10px;
}
.remove-icon {
  color: #101010;
  margin-left: 10px;
  cursor: pointer;
}
.fullscreen-icon {
  color: #101010;
  margin-left: 10px;
  cursor: pointer;
}
.edit-icon:hover {
  transition: ease 0.3s;
  color: #FF7110;
}
.remove-icon:hover {
  transition: ease 0.3s;
  color: #FF7110;
}
.fullscreen-icon:hover {
  transition: ease 0.3s;
  color: #FF7110;
}
.toggle-btn-width {
  margin: 0 !important;
  padding: 0 !important;
  position: relative;
  top: -10px
}
.input-container {
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px;
  margin-top: 5px;
  display: flex;
}
.input-value {
  border: none;
  flex: 1;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: #B3B3B3;
}
.input-value:focus {
  border: none;
  outline: none;
  color: #FF7110;
}

</style>
